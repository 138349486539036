<template lang="pug">
.card
    .card-body
        h2(@click="currentSRC")
            span.material-icons home
            | {{ $t("message.events") }}
        iframe.events-frame(v-bind:src="src" ref="iframeRef" @load="")
</template>

<script>
export default {
    data() {
        return {
            src: "https://subsplash.com/+p4y7/lb/ca/+vwkqc93?embed"
        }
    },
    methods: {
        currentSRC(){
            this.$refs.iframeRef.contentWindow.location.replace('https://subsplash.com/+p4y7/lb/ca/+vwkqc93?embed');
        }
    }
}
</script>

<style lang="sass">
.events-frame
    width: 100%
    height: 500px

</style>